var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('app-bar',{attrs:{"currentPage":"news"}}),_c('div',{style:({ height: ((_vm.windowHeight - 90) + "px") })},[_c('div',{staticClass:"d-flex align-center justify-space-between",staticStyle:{"height":"88px","letter-spacing":"1px","position":"fixed"},style:({
        top: ((_vm.isMobile ? '0' : '0.5') + "%"),
        left: ((this.windowWidth / 2 - _vm.displaySize(400, 0.11) / 2 + 7) + "px"),
        width: ((_vm.displaySize(400, 0.11)) + "px"),
        fontSize: ((_vm.displaySize(30, 0.08)) + "px"),
      })},[_c('span',{staticClass:"text-center",class:[_vm.who === 'news' ? 'white-gradient' : 'change-gradient'],staticStyle:{"width":"50%"},on:{"click":function($event){return _vm.toggleWho('news')}}},[_vm._v(" Newsletter ")]),_c('hr',{staticClass:"vertical-breakline ml-1",staticStyle:{"height":"60%"}}),_c('span',{staticClass:"text-center",class:[_vm.who === 'winners' ? 'white-gradient' : 'change-gradient'],staticStyle:{"width":"50%"},on:{"click":function($event){return _vm.toggleWho('winners')}}},[_vm._v("Winners   ")])]),(_vm.isLoading)?_c('loading-circle'):_c('div',{staticClass:"pt-7 d-flex align-center flex-column mx-auto scroll",class:[_vm.isMobile ? 'noScroll' : ''],staticStyle:{"max-width":"950px","overflow-y":"auto","height":"100%"}},_vm._l((_vm.list),function(elem,index){return _c('v-card',{key:elem._id,staticClass:"nwsCard mb-5",attrs:{"width":"90%","color":elem.color || '#343739',"elevation":"10"}},[(_vm.who === 'news')?_c('div',[(!_vm.isMobile)?_c('div',{staticClass:"d-flex"},[_c('v-col',{staticClass:"ml-3 d-flex flex-column pr-0",attrs:{"cols":9}},[_c('div',{domProps:{"innerHTML":_vm._s(elem.title)}}),_c('p',{staticClass:"ml-1 font-weight-bold font-italic text-truncate",staticStyle:{"color":"#9b9b9b"},style:({ fontSize: ((_vm.displaySize(0.75, 0.05)) + "em") })},[_vm._v(" "+_vm._s(elem.createdAt)+" (Your Time) ")]),_c('div',{domProps:{"innerHTML":_vm._s(elem.description)}})]),_c('v-col',{attrs:{"cols":3,"align-self":"center"}},[_c('div',{staticClass:"mx-auto",staticStyle:{"height":"100px","width":"100px"}},[_c('div',{style:({
                    height: '100%',
                    width: '100%',
                    background: ("url(" + (elem.image) + ") center "),
                    backgroundSize: '100% 100%',
                  })},[_c('div',{attrs:{"id":"gradup"}})])])])],1):_c('div',{staticClass:"d-flex flex-column py-5 px-2"},[_c('div',{staticClass:"pl-3",domProps:{"innerHTML":_vm._s(elem.title)}}),_c('p',{staticClass:"pl-3 ml-1 font-weight-bold font-italic text-truncate",staticStyle:{"color":"#9b9b9b"},style:({ fontSize: ((_vm.displaySize(0.75, 0.05)) + "em") })},[_vm._v(" "+_vm._s(elem.createdAt)+" (Your Time) ")]),_c('div',{staticClass:"pl-3",domProps:{"innerHTML":_vm._s(elem.description)}}),_c('div',{staticClass:"mx-auto",staticStyle:{"height":"75px","width":"75px"}},[_c('div',{style:({
                  height: '100%',
                  width: '100%',
                  background: ("url(" + (elem.image) + ") center "),
                  backgroundSize: '100% 100%',
                })},[_c('div',{attrs:{"id":"gradup"}})])])])]):_c('div',[_c('v-col',{staticClass:"ml-3 d-flex flex-column",attrs:{"cols":12}},[_c('p',{staticClass:"regularText mb-1",style:({ fontSize: ((_vm.displaySize(1.2, 0.05)) + "em") })},[_vm._v(" "+_vm._s(index + 1)+". "+_vm._s(elem.title)+": "+_vm._s(elem.winner.username)+" ")]),_c('p',{staticClass:"mx-5 white--text",staticStyle:{"font-style":"italic","font-family":"'Poppins'"},style:({ fontSize: ((_vm.displaySize(1.1, 0.03)) + "em") })},[_vm._v(" \""+_vm._s(elem.winner.comment)+"\" ")])])],1)])}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }