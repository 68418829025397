<template>
  <div>
    <app-bar currentPage="news"></app-bar>
    <div :style="{ height: `${windowHeight - 90}px` }">
      <div
        class="d-flex align-center justify-space-between"
        style="height: 88px; letter-spacing: 1px; position: fixed"
        :style="{
          top: `${isMobile ? '0' : '0.5'}%`,
          left: `${this.windowWidth / 2 - displaySize(400, 0.11) / 2 + 7}px`,
          width: `${displaySize(400, 0.11)}px`,
          fontSize: `${displaySize(30, 0.08)}px`,
        }"
      >
        <span
          class="text-center"
          :class="[who === 'news' ? 'white-gradient' : 'change-gradient']"
          style="width: 50%"
          @click="toggleWho('news')"
        >
          Newsletter
        </span>
        <hr class="vertical-breakline ml-1" style="height: 60%" />
        <span
          class="text-center"
          :class="[who === 'winners' ? 'white-gradient' : 'change-gradient']"
          style="width: 50%"
          @click="toggleWho('winners')"
          >Winners&nbsp;&nbsp;&nbsp;</span
        >
      </div>

      <loading-circle v-if="isLoading"></loading-circle>
      <div
        v-else
        class="pt-7 d-flex align-center flex-column mx-auto scroll"
        :class="[isMobile ? 'noScroll' : '']"
        style="max-width: 950px; overflow-y: auto; height: 100%"
      >
        <v-card
          v-for="(elem, index) in list"
          :key="elem._id"
          class="nwsCard mb-5"
          width="90%"
          :color="elem.color || '#343739'"
          elevation="10"
        >
          <div v-if="who === 'news'">
            <div v-if="!isMobile" class="d-flex">
              <v-col :cols="9" class="ml-3 d-flex flex-column pr-0">
                <div v-html="elem.title"></div>
                <p
                  class="ml-1 font-weight-bold font-italic text-truncate"
                  style="color: #9b9b9b"
                  :style="{ fontSize: `${displaySize(0.75, 0.05)}em` }"
                >
                  {{ elem.createdAt }} (Your Time)
                </p>
                <div v-html="elem.description"></div>
              </v-col>
              <v-col :cols="3" align-self="center">
                <div class="mx-auto" style="height: 100px; width: 100px">
                  <div
                    :style="{
                      height: '100%',
                      width: '100%',
                      background: `url(${elem.image}) center `,
                      backgroundSize: '100% 100%',
                    }"
                  >
                    <div id="gradup"></div>
                  </div>
                </div>
              </v-col>
            </div>
            <div v-else class="d-flex flex-column py-5 px-2">
              <div class="pl-3" v-html="elem.title"></div>
              <p
                class="pl-3 ml-1 font-weight-bold font-italic text-truncate"
                style="color: #9b9b9b"
                :style="{ fontSize: `${displaySize(0.75, 0.05)}em` }"
              >
                {{ elem.createdAt }} (Your Time)
              </p>
              <div class="pl-3" v-html="elem.description"></div>
              <div class="mx-auto" style="height: 75px; width: 75px">
                <div
                  :style="{
                    height: '100%',
                    width: '100%',
                    background: `url(${elem.image}) center `,
                    backgroundSize: '100% 100%',
                  }"
                >
                  <div id="gradup"></div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <v-col :cols="12" class="ml-3 d-flex flex-column">
              <p
                class="regularText mb-1"
                :style="{ fontSize: `${displaySize(1.2, 0.05)}em` }"
              >
                {{ index + 1 }}. {{ elem.title }}: {{ elem.winner.username }}
              </p>
              <p
                :style="{ fontSize: `${displaySize(1.1, 0.03)}em` }"
                class="mx-5 white--text"
                style="font-style: italic; font-family: 'Poppins'"
              >
                "{{ elem.winner.comment }}"
              </p>
            </v-col>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingCircle from "@/components/LoadingCircle.vue";
import AppBar from "@/components/AppBar.vue";

export default {
  components: { LoadingCircle, AppBar },

  data: () => ({
    who: "news",
  }),
  computed: {
    list() {
      if (this.who === "news") return this.$store.getters["news/news"];
      return this.$store.getters["main/games"].filter((game) => !game.status);
    },
    isLoading() {
      if (this.who === "news" && this.$store.getters["news/isLoading"])
        return true;
      else if (this.who === "winners" && !this.list.length) return true;
      return false;
    },
  },
  methods: {
    toggleWho(val) {
      if (this.who === val) return;
      this.who = val;
      if (this.who === "winners" && !this.$store.getters["main/games"].length)
        this.$store.dispatch({ type: "main/fetchGames" });
    },
  },
  async beforeCreate() {
    this.$store.commit("currentPage", { page: "news" });
    if (!this.$store.getters["news/news"].length)
      this.$store.dispatch({ type: "news/fetchNews" });
  },
};
</script>

<style scoped>
#gradup {
  height: 100%;
  width: 100%;
  background: radial-gradient(transparent 100%, #343739 100%);
}
.nwsCard {
  border-radius: 22px;
  box-shadow: 0px 4px 4px #00000040;
}

.white-gradient {
  background: linear-gradient(180deg, #e4e4e4 47.92%, #6d6d6d 73.44%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
}
.change-gradient {
  background: linear-gradient(
    180deg,
    rgba(228, 228, 228, 0.45) 47.92%,
    rgba(109, 109, 109, 0.45) 73.44%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
}

.change-gradient:hover {
  background: linear-gradient(180deg, #e4e4e4 47.92%, #6d6d6d 73.44%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

::-webkit-scrollbar-button {
  height: 17px;
}

/* SHADOW LA IMAGINEA DE LA NEWS LETTER */
/* 'box-shadow': `0 0 10px 7px ${elem.color || '#343739'} inset`, */
</style>
